import { useScroll, animated, useSpring } from '@react-spring/web';
import './index.css';
import styles from './styles.module.scss';
import Introduction from './Introduction';
import { IParallax, Parallax, ParallaxLayer } from '@react-spring/parallax';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Style } from 'util';
import React from 'react';
import { arrowFunctionExpression } from '@babel/types';

export function App() {
  const containerRef = useRef<HTMLDivElement>(null!);
  const fbRef = useRef<HTMLDivElement>(null!);
  const finaRef = useRef<HTMLDivElement>(null!);
  const blombergRef = useRef<HTMLDivElement>(null!);
  const fiRef = useRef<HTMLDivElement>(null!);
  const bloRef = useRef<HTMLDivElement>(null!);
  const parallaxRef = useRef<IParallax>(null!);

  const calculateScrollDistance = () => {
    const par = document.getElementById('parallax');
  };

  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const div = document.getElementById('parallax');
      if (div) {
        const { scrollTop, scrollHeight, clientHeight } = div;

        //

        // const progress = (scrollTop / (scrollHeight - clientHeight)) * 100;

        const progress = scrollTop / clientHeight;
        setScrollProgress(progress);
      }
    };

    const div = document.getElementById('parallax');
    if (div) {
      div.addEventListener('scroll', handleScroll);

      return () => {
        div.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const fiWidth = () => {
    let width = scrollProgress;

    const fina = finaRef.current?.offsetWidth;

    if (fina) {
      width = fina * scrollProgress * 1.1;
    }

    return width;
  };

  const blomebergWidth = () => {
    let width = scrollProgress;
    const blomberg = blombergRef.current?.offsetWidth;

    if (blomberg) {
      width = blomberg * scrollProgress * 1.1;
    }

    return width;
  };

  const fibloGap = () => {
    let gap = scrollProgress * 3;

    if (gap > 2) {
      gap = 2;
    }

    return gap + 'rem';
  };

  const marginTop = () => {
    let top = 30;

    if (scrollProgress >= 1) {
      top = 30 * (2.3 - scrollProgress);
    }

    if (top >= 30) {
      top = 30;
    } else if (top <= 10) {
      top = 10;
    }

    return top + 'vh';
  };

  return (
    <div ref={containerRef} className={styles.body}>
      <div className='texture'></div>
      <Parallax pages={5} ref={parallaxRef} id='parallax'>
        <ParallaxLayer
          sticky={{ start: 0, end: 4 }}
          onClick={() => parallaxRef.current.scrollTo(1)}
          className='parallax-card fiblo'
        >
          <div
            className='flex-row gap-2'
            style={{
              gap: fibloGap(),
              marginTop: marginTop(),
            }}
          >
            <animated.span
              className='pixelname'
              ref={fiRef}
              style={{
                width: fiWidth(),
                minWidth: '112px',
                maxWidth: finaRef.current?.offsetWidth,
              }}
            >
              fi
            </animated.span>
            <animated.span
              className='pixelname'
              ref={bloRef}
              style={{
                width: blomebergWidth(),
                minWidth: '238px',
                maxWidth: blombergRef.current?.offsetWidth,
              }}
            >
              Blo
            </animated.span>
          </div>
        </ParallaxLayer>
        <ParallaxLayer
          sticky={{ start: 1, end: 4 }}
          onClick={() => parallaxRef.current.scrollTo(2)}
          className='parallax-card flex-row gap-2 finablomberg'
        >
          <div style={{ marginTop: marginTop() }} className='flex-row gap-2'>
            <span className='pixelname' ref={finaRef}>
              <span className='invisible'>Fi</span>
              na
            </span>
            <span className='pixelname' ref={blombergRef}>
              <span className='invisible'>Blo</span>
              mberg
            </span>
          </div>
        </ParallaxLayer>
{/*         <ParallaxLayer
          sticky={{ start: 2, end: 4 }}
          onClick={() => parallaxRef.current.scrollTo(3)}
          className='parallax-card'
        >
          <div className='description'>I'm a front-end developer</div>
        </ParallaxLayer>
        <ParallaxLayer
          sticky={{ start: 3, end: 4 }}
          onClick={() => parallaxRef.current.scrollTo(4)}
          className='parallax-card'
        >
          <div>I'm a front-end developer</div>
        </ParallaxLayer>
        <ParallaxLayer sticky={{ start: 4, end: 4 }} className='parallax-card'>
          <div className='contact'>
            <span>Get in touch: </span>
            <a href='mailto:fina@fiblo.se'>fina@fiblo.se</a>
          </div>
        </ParallaxLayer> */}
      </Parallax>
    </div>
  );
}
